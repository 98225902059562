import React, { FC, ReactNode, useEffect } from 'react';
import styled from 'styled-components';
import { FieldProps, getIn } from 'formik';
import Label from '../Label';
import FieldMessage from '../FieldMessage';

const InputFieldStyle = styled.div`
    width: 100%;
    label {
        margin-bottom: 10px;
    }
    input {
        font-family: 'Neue Helvetica';
        font-style: normal;
        font-weight: 500;
        font-size: 14px;
        line-height: 14px;
        padding: 15px 20px;
        border-radius: 10px;
        width: 100%;
        border-color: transparent;
        color: black;
        &::placeholder {
            color: #b7b7b7;
        }
        &:focus-visible {
            outline: none;
        }
    }
`;

interface IProps extends FieldProps {
    type?: string;
    label?: string;
    placeholder?: string;
    icon?: ReactNode;
    iconHandleClick?: () => void;
    isDisabled?: boolean;
    className?: string;
}

const InputField: FC<IProps> = (props) => {
    const {
        type,
        label,
        icon,
        iconHandleClick,
        placeholder,
        field,
        isDisabled = false,
        className,
        form: { setFieldValue, errors, touched, setFieldTouched },
        ...restProps
    } = props;
    const errorMessage = getIn(errors, field.name);
    const touch = getIn(touched, field.name);
    useEffect(() => {
        if (field.value) {
            setFieldTouched(field.name, true);
        }
    }, [field.value]);
    return (
        <InputFieldStyle
            className={
                (className ? className + ' ' : '') +
                (errorMessage && touch ? 'error' : '')
            }
        >
            <div className="field">
                {label && (
                    <Label>
                        <span>{label}</span>
                    </Label>
                )}
                {icon && (
                    <div className={'icon'} onClick={iconHandleClick}>
                        {icon}
                    </div>
                )}
                <input
                    {...field}
                    {...restProps}
                    name={field.name}
                    type={type || 'text'}
                    placeholder={placeholder || ''}
                    disabled={isDisabled}
                />
            </div>
            {errorMessage && touch && (
                <FieldMessage className={'inputHintMessage'}>
                    {errorMessage}
                </FieldMessage>
            )}
        </InputFieldStyle>
    );
};

export default InputField;

import React, { FC } from 'react';
import styled from 'styled-components';
import { Flex } from './Flex';
import { v4 as uuid4 } from 'uuid';
import frontCropImage from '../../images/frontCrop.jpg';
import frontBlurImage from '../../images/frontBlur.jpg';
import { useTranslation } from 'react-i18next';

const ImageInstructionsStyle = styled.div`
    .imageNote {
        position: relative;
        display: flex;
        gap: 10px;

        &:before {
            position: relative;
            content: '-';
        }
    }
`;

interface IProps {}

const ImageInstructions: FC<IProps> = (props) => {
    const { t } = useTranslation();
    return (
        <ImageInstructionsStyle>
            <Flex direction={'column'} gap={20} width={'100%'}>
                <h4>{t('ensureThatTheDocumentIs')}</h4>
                <Flex
                    direction={'column'}
                    gap={10}
                    marginBottom={30}
                    width={'100%'}
                >
                    <Flex
                        alignItems={'center'}
                        gap={10}
                        wrap={'nowrap'}
                        key={uuid4()}
                    >
                        <img
                            className={'imageReference'}
                            src={frontCropImage}
                            alt=""
                        />
                        <p className={'imageNote'}>
                            {t(
                                'consistentWithThePreviouslyProvidedInformation',
                            )}
                        </p>
                    </Flex>
                    <Flex
                        alignItems={'center'}
                        gap={10}
                        wrap={'nowrap'}
                        key={uuid4()}
                    >
                        <img
                            className={'imageReference'}
                            src={frontCropImage}
                            alt=""
                        />
                        <p className={'imageNote'}>
                            {t('Document is not Blurred')}
                        </p>
                    </Flex>

                    <Flex
                        alignItems={'center'}
                        gap={10}
                        wrap={'nowrap'}
                        key={uuid4()}
                    >
                        <img
                            className={'imageReference'}
                            src={frontBlurImage}
                            alt=""
                        />
                        <p className={'imageNote'}>
                            {t('documentDoseNotHaveReflectionImageGlare')}
                        </p>
                    </Flex>
                    <Flex
                        alignItems={'center'}
                        gap={10}
                        wrap={'nowrap'}
                        key={uuid4()}
                    >
                        <img
                            className={'imageReference'}
                            src={frontCropImage}
                            alt=""
                        />
                        <p className={'imageNote'}>
                            {t('Document is not Cropped')}
                        </p>
                    </Flex>
                </Flex>
            </Flex>
        </ImageInstructionsStyle>
    );
};
export default ImageInstructions;

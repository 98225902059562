import styled from "styled-components";

export const QuestionStyle = styled.div`
    h3.title {
        font-family: 'Neue Helvetica';
        font-style: normal;
        font-weight: 500;
        font-size: 20px;
        line-height: 20px;
        color: #006F3C;
        margin-bottom: 30px;
        display: flex;
        align-items: center;
        gap: 5px;
        flex-wrap: wrap;
        span.blue {
            color: #0828b2;
        }
        .icon {
            margin-left: 5px;
        }
    }
    .fields {
        margin-bottom: 30px;
        & > * {
            margin-bottom: 15px;
            &:last-of-type {
                margin-bottom: 0;
            }
        }
    }
    .info {
        font-family: 'Neue Helvetica';
        font-style: normal;
        font-weight: 500;
        font-size: 12px;
        line-height: 18px;
        text-align: center;
        color: #494947;
        margin-top: 15px;
    }
    .subtitle {
        font-family: 'Neue Helvetica';
        font-style: normal;
        font-weight: 500;
        font-size: 14px;
        line-height: 20px;
        color: #292929;
        margin-bottom: 20px;
    }
    .capture {
        width: 100px;
        height: 100px;
        display: flex;
        align-items: center;
        justify-content: center;
        border-radius: 50%;
        background-color: #d9d9d9;
        margin-bottom: 15px;
    }
    .or {
        font-family: 'Neue Helvetica', serif;
        font-style: normal;
        font-weight: 500;
        font-size: 12px;
        line-height: 12px;
        color: #b7b7b7;
        margin-bottom: 30px;
    }
    .uploadImage {
        margin-bottom: 30px;
    }
    .idCardImage {
        margin-bottom: 10px;
    }
    .imageEditActions {
        button {
            width: 45px;
            height: 45px;
            background-color: #d9d9d9;
            border-radius: 10px;
            display: flex;
            align-items: center;
            justify-content: center;
        }
        margin-bottom: 20px;
    }
    .imagePreview {
        margin-bottom: 20px;
        max-width: 100%;
    }

    .uploadImageConfirmActions {
        display: flex;
        align-items: center;
        gap: 10px;
        width: 100%;
        & > * {
            width: 50%;
        }
        margin-bottom: 30px;
        button {
            gap: 5px;
            padding: 15px 15px !important;
        }
    }
    img,
    video {
        max-width: 100%;
    }
    video {
        background-color: black;
        margin: 10px 0;
    }
    .captureWithPhoneCamera {
        font-family: 'Neue Helvetica', serif;
        font-style: normal;
        font-weight: 450;
        font-size: 14px;
        line-height: 13px;
        color: #006F3C;
        margin-bottom: 30px;
    }

    ul.reference {
        margin-bottom: 20px;
        li {
            font-family: 'Neue Helvetica', serif;
            font-style: normal;
            font-weight: 500;
            font-size: 14px;
            line-height: 20px;
            color: #292929;
            margin-bottom: 5px;
            position: relative;
            &:last-of-type {
                margin-bottom: 0;
            }
            &:before {
                content: '';
                width: 5px;
                height: 5px;
                background-color: black;
                border-radius: 50%;
                top: -2px;
                left: 0;
                position: relative;
                margin-right: 5px;
                display: inline-block;
            }
        }
    }

    .imageReference {
        width: 90px;
    }
`;

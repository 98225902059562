import React, { ChangeEvent, FC, useEffect, useState } from 'react';
import NextQuestionButton from '../NextQuestionButton';
import CameraIcon from '../../ui/icons/CameraIcon';
import styled from 'styled-components';
import Button from '../../ui/Button';
import { Flex } from '../../ui/Flex';
import UploadFile from '../../ui/fields/input/UploadFile';
import { Field } from 'formik';
import { useDispatch, useSelector } from 'react-redux';
import {
    selectRegister,
    setCurrentQuestion,
    setFrontIdImage,
    setFrontIdUrl,
} from '../../../store/reducers/registerSlice';
import CaptureImage from './CaptureImage';
import { base64ToFile } from '../../../utils/base64ToFile';
import EditImage from './EditImage';
import { useTranslation } from 'react-i18next';
import { useUploadFileMutation } from '../../../store/RTKQuery/upload/uploadFile';
import { questions } from '../Register';
import ImageInstructions from '../../ui/ImageInstructions';

const Question7FrontIDStyle = styled.div`
    h3.title {
        margin-bottom: 10px !important;
    }
`;

interface IProps {}

const Question7FrontID: FC<IProps> = () => {
    const dispatch = useDispatch();
    const { frontIdImage, frontIdUrl } = useSelector(selectRegister);
    const [isCaptureImageNow, setIsCaptureImageNow] = useState<Boolean>(false);
    const [isEditImageNow, setIsEditImageNow] = useState<Boolean>(false);
    const { t } = useTranslation();
    const [uploadImage, { data, isLoading, isSuccess }] =
        useUploadFileMutation();
    const nextPageNumber: number = 8;
    const goToNextPage = () => {
        dispatch(
            setCurrentQuestion(
                questions.filter((q) => q.page === nextPageNumber)[0],
            ),
        );
    };
    useEffect(() => {
        if (isSuccess && data) {
            dispatch(setFrontIdUrl(data.file_path));
            goToNextPage();
        }
    }, [isSuccess]);
    return (
        <Question7FrontIDStyle>
            <h3 className="title">{t('imageOfTheFrontOfYourID')}</h3>
            {!isCaptureImageNow && (
                <p className={'subtitle'}>
                    {t('makeSureItMatchesTheDetailsYouProvidedEarlier')}
                </p>
            )}
            <Flex direction={'column'} alignItems={'center'}>
                {!frontIdImage.original ? (
                    <>
                        {!isCaptureImageNow ? (
                            <>
                                <button
                                    className={'capture'}
                                    type={'button'}
                                    onClick={() => {
                                        setIsCaptureImageNow(true);
                                    }}
                                >
                                    <CameraIcon />
                                </button>
                                <p className={'captureWithPhoneCamera'}>
                                    {t('captureWithPhoneCamera')}
                                </p>
                                <p className="or">{t('or')}</p>
                                <Field
                                    name={'front_id_imageFormData'}
                                    text={t('uploadImage')}
                                    className={'uploadImage'}
                                    onChange={(
                                        event: ChangeEvent<HTMLInputElement>,
                                    ) => {
                                        if (event.currentTarget.files) {
                                            dispatch(
                                                setFrontIdImage({
                                                    original:
                                                        event.currentTarget
                                                            .files[0],
                                                    upload: event.currentTarget
                                                        .files[0],
                                                }),
                                            );
                                        }
                                    }}
                                    component={UploadFile}
                                />
                            </>
                        ) : (
                            <CaptureImage
                                cameraType={'environment'}
                                onCapture={(imageSrc) => {
                                    dispatch(
                                        setFrontIdImage({
                                            original: base64ToFile(
                                                imageSrc,
                                                'FrontIdImage',
                                            ),
                                            upload: base64ToFile(
                                                imageSrc,
                                                'FrontIdImage',
                                            ),
                                        }),
                                    );
                                    setIsCaptureImageNow(false);
                                }}
                                onBack={() => {
                                    setIsCaptureImageNow(false);
                                }}
                            />
                        )}
                    </>
                ) : (
                    <>
                        {isEditImageNow && frontIdImage.original ? (
                            <EditImage
                                onEdit={(imageSrc) => {
                                    dispatch(
                                        setFrontIdImage({
                                            original: frontIdImage.original,
                                            upload: base64ToFile(
                                                imageSrc,
                                                'FrontIdImage',
                                            ),
                                        }),
                                    );
                                    dispatch(setFrontIdUrl(''));
                                    setIsEditImageNow(false);
                                }}
                                image={frontIdImage.original}
                                onBack={() => {
                                    setIsEditImageNow(false);
                                }}
                            />
                        ) : (
                            <>
                                {frontIdImage.upload && (
                                    <img
                                        src={URL.createObjectURL(
                                            frontIdImage.upload,
                                        )}
                                        alt=""
                                        className={'imagePreview'}
                                    />
                                )}
                                {/*       <Flex
                                    alignItems={'center'}
                                    gap={10}
                                    className={'imageEditActions'}
                                    marginBottom={20}
                                >
                                    <button
                                        type={'button'}
                                        onClick={() => {
                                            setIsEditImageNow(true);
                                        }}
                                    >
                                        <CropIcon />
                                    </button>
                                    <button
                                        type={'button'}
                                        onClick={() => {
                                            setIsEditImageNow(true);
                                        }}
                                    >
                                        <RotateIcon />
                                    </button>
                                </Flex>*/}

                                <div className={'uploadImageConfirmActions'}>
                                    <Button
                                        text={t('captureAgain') || ''}
                                        variant={'outline'}
                                        handleClick={() => {
                                            dispatch(
                                                setFrontIdImage({
                                                    original: null,
                                                    upload: null,
                                                }),
                                            );
                                            dispatch(setFrontIdUrl(''));
                                        }}
                                    />
                                    <NextQuestionButton
                                        canNext={true}
                                        nextPage={nextPageNumber}
                                        text={t('yesContinue') || ''}
                                        isLoading={isLoading}
                                        onClick={() => {
                                            if (!frontIdUrl) {
                                                const formData = new FormData();
                                                formData.append(
                                                    'file',
                                                    frontIdImage.upload as File,
                                                );
                                                uploadImage(formData);
                                            } else {
                                                goToNextPage();
                                            }
                                        }}
                                    />
                                </div>
                            </>
                        )}
                    </>
                )}
                {!isCaptureImageNow && !isEditImageNow && <ImageInstructions />}
            </Flex>
        </Question7FrontIDStyle>
    );
};
export default Question7FrontID;

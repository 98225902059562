import { FC } from 'react';

interface IProps {
    className?: string;
}

const CameraIcon: FC<IProps> = (props) => {
    const { className } = props;
    return (
        <svg
            className={className}
            width="27"
            height="24"
            viewBox="0 0 27 24"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
        >
            <path
                d="M23.625 3.4375H19.8281L19.3535 2.27734C18.8789 0.958984 17.6133 0.0625 16.1895 0.0625H10.7578C9.33398 0.0625 8.06836 0.958984 7.59375 2.27734L7.17188 3.4375H3.375C1.47656 3.4375 0 4.9668 0 6.8125V20.3125C0 22.2109 1.47656 23.6875 3.375 23.6875H23.625C25.4707 23.6875 27 22.2109 27 20.3125V6.8125C27 4.9668 25.4707 3.4375 23.625 3.4375ZM24.4688 20.3125C24.4688 20.7871 24.0469 21.1562 23.625 21.1562H3.375C2.90039 21.1562 2.53125 20.7871 2.53125 20.3125V6.8125C2.53125 6.39062 2.90039 5.96875 3.375 5.96875H8.91211L9.49219 4.33398L9.9668 3.17383C10.0723 2.85742 10.3887 2.59375 10.7578 2.59375H16.1895C16.5586 2.59375 16.875 2.85742 16.9805 3.17383L17.4551 4.33398L18.0352 5.96875H23.625C24.0469 5.96875 24.4688 6.39062 24.4688 6.8125V20.3125ZM13.5 7.65625C10.2305 7.65625 7.59375 10.3457 7.59375 13.5625C7.59375 16.832 10.2305 19.4688 13.5 19.4688C16.7168 19.4688 19.4062 16.832 19.4062 13.5625C19.4062 10.3457 16.7168 7.65625 13.5 7.65625ZM13.5 16.9375C11.6016 16.9375 10.125 15.4609 10.125 13.5625C10.125 11.7168 11.6016 10.1875 13.5 10.1875C15.3457 10.1875 16.875 11.7168 16.875 13.5625C16.875 15.4609 15.3457 16.9375 13.5 16.9375Z"
                fill="#006F3C"
            />
        </svg>
    );
};
export default CameraIcon;

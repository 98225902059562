import { FC, useEffect } from 'react';
import { Field } from 'formik';
import NextQuestionButton from '../NextQuestionButton';
import RadioButtonField from '../../ui/fields/radio-button/RadioButtonField';
import styled from 'styled-components';
import InfoQuestionButton from '../InfoQuestionButton';
import { WhatDoYouDoEnum } from '../../../enums/WhatDoYouDoEnum';
import { useDispatch, useSelector } from 'react-redux';
import {
    selectRegister,
    setCurrentJob,
} from '../../../store/reducers/registerSlice';
import { useTranslation } from 'react-i18next';
import InputField from '../../ui/fields/input/InputField';

const Question5WhatDoYouDoStyle = styled.div``;

interface IProps {
    values: any;
}

const Question5WhatDoYouDo: FC<IProps> = (props) => {
    const { values } = props;
    const dispatch = useDispatch();
    const { current_job } = useSelector(selectRegister);
    useEffect(() => {
        dispatch(setCurrentJob(values.current_job));
    }, [values]);
    const { t } = useTranslation();
    console.log(current_job);
    return (
        <Question5WhatDoYouDoStyle>
            <h3 className="title">
                {t('whatDoYouDo')}
                <InfoQuestionButton />
            </h3>
            <div className="fields">
                <Field
                    name={'current_job'}
                    id="answer1"
                    label={t('iAmNotEmployed')}
                    value={WhatDoYouDoEnum.NotEmployed}
                    component={RadioButtonField}
                />
                <Field
                    name={'current_job'}
                    id="answer2"
                    label={t('iAmRetired')}
                    value={WhatDoYouDoEnum.Retired}
                    component={RadioButtonField}
                />
                <Field
                    name={'current_job'}
                    id="answer3"
                    label={t('iAmStudying')}
                    value={WhatDoYouDoEnum.Studying}
                    component={RadioButtonField}
                />
                <Field
                    name={'current_job'}
                    id="answer4"
                    label={t('iAmEmployed')}
                    value={WhatDoYouDoEnum.Employed}
                    component={RadioButtonField}
                />
                {current_job != WhatDoYouDoEnum.Employed && (
                    <Field
                        label={t('sourceIncome')}
                        placeholder={t('enterYourSourceIncome')}
                        name={'company.source_income'}
                        component={InputField}
                    />
                )}
            </div>

            <NextQuestionButton
                nextPage={6}
                canNext={
                    values.company.source_income ||
                    current_job === WhatDoYouDoEnum.Employed
                }
            />
            <p className="info">{t('questionInfo')}</p>
        </Question5WhatDoYouDoStyle>
    );
};
export default Question5WhatDoYouDo;

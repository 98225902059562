import { FieldProps, getIn } from 'formik';
import React, { ReactNode } from 'react';
import styled from 'styled-components';
import { RadioButton } from './RadioButton';
import FieldMessage from '../FieldMessage';
import { useTranslation } from 'react-i18next';

const RadioButtonFieldStyle = styled.div`
    .field {
        display: flex;
        align-items: center;

        &.rtl {
            input {
                margin-right: 0;
                margin-left: 5px;
            }
        }
    }

    &.unchecked {
        .field {
            label {
                p {
                }
            }
        }
    }

    &.checked {
        .field {
            label {
                p {
                }
            }

            input {
                &:after {
                }
            }
        }
    }

    &.error {
        .inputHintMessage {
            margin-left: 25px;
        }
    }
`;

interface IProps extends FieldProps {
    label?: ReactNode | string;
    onChange?: (newValue: boolean) => void;
    id: string;
    value: any;
}

const RadioButtonField: React.FC<IProps> = (props) => {
    const {
        label,
        field,
        id,
        value,
        onChange,
        form: { errors, touched, handleChange },
        ...restProps
    } = props;
    const errorMessage = getIn(errors, field.name);
    const touch = getIn(touched, field.name);
    const { i18n } = useTranslation();
    return (
        <RadioButtonFieldStyle>
            <div className={'field ' + i18n.dir()}>
                <RadioButton
                    {...field}
                    {...restProps}
                    name={field.name}
                    id={id}
                    type={'radio'}
                    value={value}
                    marginRight={5}
                    checked={field.value === value}
                    onChange={(event) => {
                        handleChange(event);
                        if (onChange) {
                            onChange(event.target.checked);
                        }
                    }}
                />
                <label htmlFor={id}>{label}</label>
            </div>
            {errorMessage && touch && (
                <FieldMessage className={'inputHintMessage'}>
                    {errorMessage}
                </FieldMessage>
            )}
        </RadioButtonFieldStyle>
    );
};
export default RadioButtonField;

import { FC } from 'react';
import styled from 'styled-components';
import {
    selectRegister,
    setCurrentQuestion,
} from '../../store/reducers/registerSlice';
import { questions } from './Register';
import Button from '../ui/Button';
import { useDispatch, useSelector } from 'react-redux';
import { WhatDoYouDoEnum } from '../../enums/WhatDoYouDoEnum';
import { useTranslation } from 'react-i18next';

const NextQuestionButtonStyle = styled.div``;

interface IProps {
    nextPage: number;
    text?: string;
    canNext: boolean;
    onClick?: () => void;
    isLoading?: boolean;
}

const NextQuestionButton: FC<IProps> = (props) => {
    const { nextPage, text, canNext = false, onClick, isLoading } = props;
    const { currentQuestion, current_job } = useSelector(selectRegister);
    const dispatch = useDispatch();
    const { t } = useTranslation();
    return (
        <NextQuestionButtonStyle>
            <Button
                text={text || t('next') || ''}
                disabled={!canNext || isLoading}
                isLoading={isLoading}
                handleClick={() => {
                    if (onClick) {
                        onClick();
                    } else {
                        if (currentQuestion && canNext) {
                            let nextPageNumber = nextPage;
                            if (
                                current_job !== WhatDoYouDoEnum.Employed &&
                                nextPage === 6
                            ) {
                                nextPageNumber = 7;
                            }
                            dispatch(
                                setCurrentQuestion(
                                    questions.filter(
                                        (q) => q.page === nextPageNumber,
                                    )[0],
                                ),
                            );
                        }
                    }
                }}
            />
        </NextQuestionButtonStyle>
    );
};
export default NextQuestionButton;

import { FC } from 'react';

interface IProps {
    className?: string;
    onClick?: () => void;
}

const ArrowIcon: FC<IProps> = (props) => {
    const { onClick, className } = props;
    return (
        <svg
            width="20"
            height="19"
            viewBox="0 0 20 19"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
            className={className}
            onClick={() => {
                if (onClick) onClick();
            }}
        >
            <path
                d="M20 8.26389L4.7875 8.26389L11.775 1.66458L10 0L0 9.44444L10 18.8889L11.7625 17.2243L4.7875 10.625L20 10.625V8.26389Z"
                fill="#006F3C"
            />
        </svg>
    );
};
export default ArrowIcon;

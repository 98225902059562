import { FC } from 'react';

interface IProps {
    className?: string;
}

const PhoneIcon: FC<IProps> = (props) => {
    const { className } = props;
    return (
        <svg
            className={className}
            width="17"
            height="17"
            viewBox="0 0 17 17"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
        >
            <path
                d="M5.15625 1.78125C5.03125 1.46875 4.83333 1.25 4.5625 1.125C4.27083 1 3.96875 0.96875 3.65625 1.03125L0.90625 1.78125C0.34375 1.98958 0.0416667 2.39583 0 3C0.0208333 5.60417 0.65625 7.95833 1.90625 10.0625C3.15625 12.1667 4.83333 13.8438 6.9375 15.0938C9.04167 16.3438 11.3958 16.9792 14 17C14.6042 16.9583 15.0104 16.6562 15.2188 16.0938L15.9688 13.3438C16.0312 13.0312 16 12.7292 15.875 12.4375C15.75 12.1667 15.5312 11.9688 15.2188 11.8438L12.2188 10.5938C11.6562 10.4062 11.1771 10.5312 10.7812 10.9688L9.53125 12.5C8.42708 11.9792 7.4375 11.2917 6.5625 10.4375C5.70833 9.5625 5.02083 8.57292 4.5 7.46875L6.03125 6.21875C6.46875 5.82292 6.59375 5.34375 6.40625 4.78125L5.15625 1.78125Z"
                fill="white"
            />
        </svg>
    );
};
export default PhoneIcon;

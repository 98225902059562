import { FC } from 'react';

interface IProps {
    className?: string;
}

const EmailIcon: FC<IProps> = (props) => {
    const { className } = props;
    return (
        <svg
            className={className}
            width="16"
            height="12"
            viewBox="0 0 16 12"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
        >
            <path
                d="M1.5 0C1.08333 0.0208333 0.729167 0.166667 0.4375 0.4375C0.166667 0.729167 0.0208333 1.08333 0 1.5C0.0208333 2 0.21875 2.39583 0.59375 2.6875L7.40625 7.8125C7.80208 8.0625 8.19792 8.0625 8.59375 7.8125L15.4062 2.6875C15.7812 2.39583 15.9792 2 16 1.5C15.9792 1.08333 15.8333 0.729167 15.5625 0.4375C15.2708 0.166667 14.9167 0.0208333 14.5 0H1.5ZM0 3.5V10C0.0208333 10.5625 0.21875 11.0312 0.59375 11.4062C0.96875 11.7812 1.4375 11.9792 2 12H14C14.5625 11.9792 15.0312 11.7812 15.4062 11.4062C15.7812 11.0312 15.9792 10.5625 16 10V3.5L9.1875 8.59375C8.83333 8.86458 8.4375 9 8 9C7.5625 9 7.16667 8.86458 6.8125 8.59375L0 3.5Z"
                fill="white"
            />
        </svg>
    );
};
export default EmailIcon;

import React from 'react';
import PhoneInput from 'react-phone-input-2';
import { FieldProps, getIn } from 'formik';
import styled from 'styled-components';
import Label from '../Label';
import FieldMessage from '../FieldMessage';
import { useTranslation } from 'react-i18next';

const PhoneFieldStyle = styled.div`
    .field {
        position: relative;
        .arrowIcon {
            position: absolute;
            bottom: 15px;
            left: 26px;
            transform: rotateZ(0);
        }
        label {
            margin-bottom: 10px;
        }
        .react-tel-input {
            display: flex;
            align-items: center;
            flex-direction: row-reverse;
            input {
                font-family: 'Neue Helvetica';
                font-style: normal;
                font-weight: 500;
                font-size: 14px;
                line-height: 14px;
                padding: 15px 20px;
                border-radius: 10px;
                border-color: transparent;
                color: black;
                flex: 1;
                height: auto;
                &::placeholder {
                    color: #b7b7b7;
                }
                &:focus-visible {
                    outline: none;
                }
            }
            .flag-dropdown {
                position: relative;
                background-color: #ffffff;
                border-radius: 10px;
                padding: 15px 10px;
                border-color: transparent;
                margin-right: 10px;
                height: 50px;
            }
            .flag-dropdown.open .selected-flag,
            .selected-flag:hover,
            .selected-flag:focus {
                background-color: transparent;
            }
        }
        &.rtl {
            .flag-dropdown {
                margin-right: 0;
                margin-left: 10px;
                .selected-flag {
                    display: flex;
                    align-items: center;
                    justify-content: center;
                    .flag {
                        .arrow {
                            left: unset;
                            right: 20px;
                        }
                    }
                }
            }
        }
    }

    &.focused {
        .field {
            input {
                &::placeholder {
                    opacity: 1;
                }
            }
        }
    }
    &.error {
        .field {
            input {
            }
        }
        .inputHintMessage {
        }
    }
`;

interface IProps extends FieldProps {
    label?: string;
    placeholder?: string;
    disabled?: boolean;
}

const PhoneField: React.FC<IProps> = (props) => {
    const {
        label,
        placeholder,
        field,
        disabled = false,
        form: { errors, setFieldTouched, setFieldValue, handleChange, touched },
        ...restProps
    } = props;
    const errorMessage = getIn(errors, field.name);
    const touch = getIn(touched, field.name);
    const { i18n } = useTranslation();
    return (
        <PhoneFieldStyle
            className={
                (errorMessage && touch ? 'error' : '') +
                (disabled ? 'disabled' : '')
            }
        >
            <div className={'field ' + i18n.dir()}>
                {label && <Label>{label}</Label>}
                <PhoneInput
                    {...field}
                    {...restProps}
                    disabled={disabled}
                    placeholder={placeholder || ''}
                    country={'eg'}
                    specialLabel={''}
                    inputClass={'input'}
                    inputStyle={{
                        direction: 'ltr',
                        textAlign: 'left',
                    }}
                    inputProps={{
                        id: field.name,
                    }}
                    onChange={(value, data, event) => {
                        if (value) {
                            handleChange(event);
                        } else {
                            setFieldValue(field.name, '');
                        }
                        if (!touch) {
                            setFieldTouched(field.name, true);
                        }
                    }}
                />
            </div>
            {errorMessage && touch && (
                <FieldMessage className={'inputHintMessage'}>
                    {errorMessage}
                </FieldMessage>
            )}
        </PhoneFieldStyle>
    );
};
export default PhoneField;

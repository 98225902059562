import React from 'react';
import styled from 'styled-components';
import { range } from 'lodash';
import { v4 as uuid4 } from 'uuid';

const StrengthBarStyle = styled.div`
    display: flex;
    align-items: center;
    height: 8px;
    background-color: #e6e6e6;
    border-radius: 10px;
    overflow: hidden;

    .bar {
        display: block;
        height: 100%;
        flex: 1;

        &.active {
            background-color: #006f3c;
        }
    }
`;

interface IProps {
    strength: number;
    className?: string;
}

const StrengthBar: React.FC<IProps> = (props) => {
    const { strength, className } = props;
    return (
        <StrengthBarStyle className={className}>
            {range(0, 13).map((value) => (
                <span
                    key={uuid4()}
                    className={(strength > value ? 'active ' : '') + 'bar'}
                ></span>
            ))}
        </StrengthBarStyle>
    );
};
export default StrengthBar;

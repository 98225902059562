import styled from "styled-components";

interface IProps {
    gap?: number;

    alignItems?: 'center' | 'flex-end';
    justifyContent?: 'center' | 'flex-end' | 'space-between';
    direction?: 'column';
    wrap?: 'nowrap';
    width?: string;
    height?: string;
    marginBottom?: number;
    marginTop?: number;
    firstChildShrink?: number;
}

export const Flex = styled.div<IProps>`
    display: flex;
    width: ${(props) => props.width || 'auto'};
    height: ${(props) => props.height || 'auto'};
    gap: ${(props) => props.gap || 0}px;
    align-items: ${(props) => props.alignItems || 'flex-start'};
    justify-content: ${(props) => props.justifyContent || 'flex-start'};
    flex-direction: ${(props) => props.direction || 'row'};
    flex-wrap: ${(props) => props.wrap || 'wrap'};

    ${(props) =>
        props.firstChildShrink &&
        `
   & > *:first-child {
        flex-shrink: ${props.firstChildShrink};
    }
  
  `}

    ${(props) =>
        props.marginBottom &&
        `
  margin-bottom: ${props.marginBottom}px;
  `}

    ${(props) =>
        props.marginTop &&
        `
  margin-top: ${props.marginTop}px;
  `}
`;

import { FC } from 'react';
import { Field } from 'formik';
import InputField from '../../ui/fields/input/InputField';
import NextQuestionButton from '../NextQuestionButton';
import styled from 'styled-components';
import InfoQuestionButton from '../InfoQuestionButton';
import { useTranslation } from 'react-i18next';

const Question6CompanyDetailsStyle = styled.div``;

interface IProps {
    errors: any;
    touched: any;
}

const Question6CompanyDetails: FC<IProps> = (props) => {
    const { errors, touched } = props;
    const { t } = useTranslation();
    return (
        <Question6CompanyDetailsStyle>
            <h3 className="title">
                {t('companyDetails')}
                <InfoQuestionButton />
            </h3>
            <div className="fields">
                <Field
                    label={t('companyName')}
                    placeholder={t('enterYourCompanyName')}
                    name={'company.name'}
                    component={InputField}
                />
                <Field
                    label={t('jobTitle')}
                    placeholder={t('enterYourJobTitle')}
                    name={'company.job_title'}
                    component={InputField}
                />
                <Field
                    label={t('numberOfYearsOfEmployment')}
                    placeholder={t('enterYourNumberOfYears')}
                    name={'company.years'}
                    type={'number'}
                    component={InputField}
                />
            </div>
            <NextQuestionButton
                nextPage={7}
                canNext={
                    errors.company?.name == null &&
                    errors.company?.job_title == null &&
                    errors.company?.years == null &&
                    touched.company?.name === true &&
                    touched.company?.job_title === true &&
                    touched.company?.years === true
                }
            />
            <p className="info">{t('questionInfo')}</p>
        </Question6CompanyDetailsStyle>
    );
};
export default Question6CompanyDetails;

import React, { FC, useCallback, useRef } from 'react';
import styled from 'styled-components';
import Webcam from 'react-webcam';
import Button from '../../ui/Button';
import { Flex } from '../../ui/Flex';
import { useTranslation } from 'react-i18next';

const CaptureImageStyle = styled.div``;

interface IProps {
    onCapture: (imageSrc: string) => void;
    onBack: () => void;
    cameraType?: 'environment' | 'user';
}

const CaptureImage: FC<IProps> = (props) => {
    const { onCapture, onBack, cameraType = 'user' } = props;
    const { t } = useTranslation();
    const webcamRef = useRef<Webcam>(null);

    const handleCapture = useCallback(() => {
        const imageSrc = webcamRef.current?.getScreenshot();
        if (imageSrc) {
            onCapture(imageSrc);
        }
    }, [webcamRef]);
    return (
        <CaptureImageStyle>
            <Webcam
                audio={false}
                height={300}
                ref={webcamRef}
                minScreenshotHeight={720}
                minScreenshotWidth={1280}
                videoConstraints={{
                    facingMode: cameraType,
                }}
                mirrored={cameraType !== 'environment'}
                screenshotFormat={'image/webp'}
            />
            <Flex alignItems={'center'} gap={15} wrap={'nowrap'}>
                <Button
                    text={t('back') || ''}
                    variant={'outline'}
                    handleClick={() => {
                        onBack();
                    }}
                />
                <Button
                    text={t('capture') || ''}
                    handleClick={() => {
                        handleCapture();
                    }}
                />
            </Flex>
        </CaptureImageStyle>
    );
};
export default CaptureImage;

import { WhatDoYouDoEnum } from '../enums/WhatDoYouDoEnum';
import { YesNoEnum } from '../enums/YesNoEnum';

export interface ICompanyModel {
    name: string;
    job_title: string;
    years: string;
    source_income: string;
}

export class CompanyModel implements ICompanyModel {
    name = '';
    job_title = '';
    years = '';
    source_income = '';
}

export interface IBankModel {
    name: string;
    currency_type: string;
    account_number: string;
}

export class BankModel implements IBankModel {
    name = '';
    currency_type = '';
    account_number = '';
}

export interface IRegisterModel {
    mobile: string;
    email: string;
    front_id_image: string;
    back_id_image: string;
    face_image: string;
    company: ICompanyModel;
    bank: IBankModel;
    nationality: string;
    full_name: string;
    current_job: WhatDoYouDoEnum;
    doYouHoldAnyOtherNationalities: YesNoEnum;
}

export class RegisterModel implements IRegisterModel {
    mobile = '';
    email = '';
    company = new CompanyModel();
    bank = new BankModel();
    face_image = '';
    back_id_image = '';
    front_id_image = '';
    nationality = '';
    full_name = '';
    current_job = WhatDoYouDoEnum.NotEmployed;
    doYouHoldAnyOtherNationalities = YesNoEnum.No;
}

import { FC, useState } from 'react';
import styled from 'styled-components';
import QuestionMark2Icon from '../ui/icons/QuestionMark2Icon';
import InfoModal from '../ui/modals/InfoModal';

const InfoQuestionButtonStyle = styled.div`
    display: inline-block;

    .icon {
        width: 16px;
        height: 16px;
        display: flex;
        align-items: center;
        justify-content: center;
        border-radius: 50%;
        cursor: pointer;
    }
`;

interface IProps {}

const InfoQuestionButton: FC<IProps> = (props) => {
    const [openModal, setOpenModal] = useState<boolean>();
    return (
        <InfoQuestionButtonStyle>
            <div
                className="icon"
                onClick={() => {
                    setOpenModal(true);
                }}
            >
                <QuestionMark2Icon />
            </div>
            {openModal && (
                <InfoModal
                    onCloseModal={() => {
                        setOpenModal(false);
                    }}
                />
            )}
        </InfoQuestionButtonStyle>
    );
};
export default InfoQuestionButton;

import React, { FC } from 'react';
import styled from 'styled-components';
import Button from '../../ui/Button';
import InfoQuestionButton from '../InfoQuestionButton';
import { Field, FormikValues } from 'formik';
import CheckboxField from '../../ui/fields/checkbox/CheckboxField';
import { useDispatch } from 'react-redux';
import { useTranslation } from 'react-i18next';

const Question10Style = styled.div`
    .checkboxFields {
        span.link {
            color: #e24335;
        }
    }
`;

interface IProps {
    isLoading: boolean;
    isValid: boolean;
    values: FormikValues;
}

const Question10: FC<IProps> = (props) => {
    const { isValid, isLoading, values } = props;
    const dispatch = useDispatch();
    const { t, i18n } = useTranslation();
    return (
        <Question10Style>
            <h3 className="title">
                {t('termsAndConditions')}
                <InfoQuestionButton />
            </h3>
            <div className="fields checkboxFields">
                <Field
                    name={`checkbox1`}
                    label={
                        <span>
                            {t(
                                'allProvidedInformationIsAccurateAndUnderMyResponsibility',
                            )}
                        </span>
                    }
                    component={CheckboxField}
                />
            </div>
            <Button
                text={t('I agree on all Terms') || ''}
                type={'submit'}
                isLoading={isLoading}
                disabled={isLoading || !isValid}
            />
        </Question10Style>
    );
};
export default Question10;

import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { RootState } from '../index';

export type LanguageType = 'ar' | 'en';

export interface IUtilityState {
    lang: LanguageType;
}

const initialState: IUtilityState = {
    lang: 'ar',
};
export const utilitySlice = createSlice({
    name: 'utilitySlice',
    initialState,
    reducers: {
        setLanguage: (state, action: PayloadAction<LanguageType>) => {
            state.lang = action.payload;
        },
    },
});

export const { setLanguage } = utilitySlice.actions;
export const selectUtility = (state: RootState) => state.utilitySlice;

export default utilitySlice.reducer;

import { FC } from 'react';
import { Field, FormikValues } from 'formik';
import InputField from '../../ui/fields/input/InputField';
import NextQuestionButton from '../NextQuestionButton';
import BankNameSelect from '../../ui/lookups/BankNameSelect';
import CurrencyTypeSelect from '../../ui/lookups/CurrencyTypeSelect';
import styled from 'styled-components';
import { useTranslation } from 'react-i18next';

const Question4Style = styled.div``;

interface IProps {
    values: FormikValues;
}

const Question4BankDetails: FC<IProps> = (props) => {
    const { values } = props;
    const { t } = useTranslation();
    return (
        <Question4Style>
            <h3 className="title">{t('bankDetails')}</h3>
            <div className="fields">
                <BankNameSelect
                    name={'bank.name'}
                    label={t('bankName') || ''}
                    placeholder={t('bankName') || ''}
                />
                <CurrencyTypeSelect
                    name={'bank.currency_type'}
                    label={t('currencyType') || ''}
                    placeholder={t('currencyType') || ''}
                />
                <Field
                    label={t('accountNumber')}
                    placeholder={t('enterYourAccountNumber')}
                    name={'bank.account_number'}
                    type={'number'}
                    component={InputField}
                />
            </div>
            <NextQuestionButton
                nextPage={5}
                canNext={values.bank.account_number}
            />
            <p className="info">{t('questionInfo')}</p>
        </Question4Style>
    );
};
export default Question4BankDetails;

import { FC, ReactNode, useEffect } from 'react';
import styled, { StyleSheetManager } from 'styled-components';
import { useTranslation } from 'react-i18next';
import rtlPlugin from 'stylis-plugin-rtl';
import { useSelector } from 'react-redux';
import { selectUtility } from '../store/reducers/utilitySlice';

const LayoutStyle = styled.div`
    &.rtl {
        * {
            font-family: 'Cairo', serif !important;
        }
    }
`;

interface IProps {
    children: ReactNode;
}

const Layout: FC<IProps> = (props) => {
    const { children } = props;
    const { i18n } = useTranslation();
    const { lang } = useSelector(selectUtility);
    useEffect(() => {
        i18n.changeLanguage(lang);
        document.body.dir = i18n.dir();
    }, [lang]);
    return (
        <LayoutStyle className={i18n.dir()}>
            <StyleSheetManager stylisPlugins={[rtlPlugin]}>
                {children}
            </StyleSheetManager>
        </LayoutStyle>
    );
};
export default Layout;

import React, { useEffect, useState } from 'react';
import SelectFieldQuery from '../fields/select/SelectFieldQuery';
import { useGetLookupsQuery } from '../../../store/RTKQuery/lookups/getLookups';
import { ISelectItem } from '../../../models/select-item';
import { convertLookupsToSelectItem } from '../../../utils/convertToSelectItem';
import { useSelector } from 'react-redux';
import { selectUtility } from '../../../store/reducers/utilitySlice';

interface IProps {
    name: string;
    className?: string;
    label?: string;
    placeholder?: string;
    isDisabled?: boolean;
}

const CitizenshipSelect: React.FC<IProps> = (props) => {
    const { name, className, label, isDisabled, placeholder } = props;
    const { lang } = useSelector(selectUtility);

    const { data, isSuccess } = useGetLookupsQuery({
        lang: lang,
    });
    const [options, setOptions] = useState<ISelectItem[]>([]);
    useEffect(() => {
        if (isSuccess && data) {
            setOptions(
                convertLookupsToSelectItem(data.data.nationalities, 'name'),
            );
        }
    }, [data, isSuccess]);
    return (
        <SelectFieldQuery
            name={name}
            label={label}
            placeholder={placeholder}
            className={className}
            autoSelectFirst={true}
            isDisabled={isDisabled}
            data={options}
        />
    );
};
export default CitizenshipSelect;

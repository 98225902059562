import { FC } from 'react';
import CitizenshipSelect from '../../ui/lookups/CitizenshipSelect';
import NextQuestionButton from '../NextQuestionButton';
import styled from 'styled-components';
import InfoQuestionButton from '../InfoQuestionButton';
import { useTranslation } from 'react-i18next';
import { useGetLookupsQuery } from '../../../store/RTKQuery/lookups/getLookups';
import { useSelector } from 'react-redux';
import { selectUtility } from '../../../store/reducers/utilitySlice';
import { Field, useFormikContext } from 'formik';
import RadioButtonField from '../../ui/fields/radio-button/RadioButtonField';
import { YesNoEnum } from '../../../enums/YesNoEnum';
import { IRegisterModel } from '../../../models/register';

const Question1CitizenshipStyle = styled.div`
    .do-you-hold-any-other-nationalities {
        margin-bottom: 20px;

        h4 {
            margin-bottom: 10px;
        }

        .do-you-hold-any-other-nationalities-answers {
            display: flex;
            align-items: center;
            gap: 30px;
        }
    }
`;

interface IProps {}

const Question1Citizenship: FC<IProps> = (props) => {
    const { t } = useTranslation();
    const { lang } = useSelector(selectUtility);
    const { values } = useFormikContext<IRegisterModel>();
    useGetLookupsQuery({
        lang: lang,
    });
    return (
        <Question1CitizenshipStyle>
            <h3 className="title">
                {t('whatIsYourCitizenship')}
                <InfoQuestionButton />
            </h3>
            <div className="do-you-hold-any-other-nationalities">
                <h4>{t('doYouHoldAnyOtherNationalities')}</h4>
                <div className="do-you-hold-any-other-nationalities-answers">
                    <Field
                        name={'doYouHoldAnyOtherNationalities'}
                        id="doYouHoldAnyOtherNationalitiesAnswer1"
                        label={t('yes')}
                        value={YesNoEnum.Yes}
                        component={RadioButtonField}
                    />
                    <Field
                        name={'doYouHoldAnyOtherNationalities'}
                        id="doYouHoldAnyOtherNationalitiesAnswer2"
                        label={t('no')}
                        value={YesNoEnum.No}
                        component={RadioButtonField}
                    />
                </div>
            </div>
            <div className="fields">
                <CitizenshipSelect
                    isDisabled={
                        values.doYouHoldAnyOtherNationalities === YesNoEnum.No
                    }
                    label={t('citizenshipStar') || ''}
                    placeholder={t('citizenship') || ''}
                    name={'nationality'}
                />
            </div>
            <NextQuestionButton nextPage={2} canNext={true} />
            <p className="info">{t('questionInfo')}</p>
        </Question1CitizenshipStyle>
    );
};
export default Question1Citizenship;
